/**
 * @prettier
 * @flow
 */
import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import AsideTeaser from '../AsideTeaser';

const AboutTeaser = () => (
    <StaticQuery
        query={graphql`
            {
                content: contentfulContentTeaser(tags: {in: "about-teaser"}) {
                    title
                    content {
                        content
                    }
                    ctaLabel
                    ctaPath
                }
            }
        `}
        render={data => {
            const {content} = data;

            if (content) {
                return (
                    <AsideTeaser
                        title={content.title}
                        content={content.content.content}
                        cta={{to: content.ctaPath, label: content.ctaLabel}}
                        theme={content.theme}
                    />
                );
            }
        }}
    />
);

export default AboutTeaser;
