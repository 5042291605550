/**
 * @prettier
 * @flow
 */
import styled from '@emotion/styled';
import {rem} from 'polished';
import {spacing, themes} from '../../globals/variables';

const accentSize = 220;

export const Container = styled.aside`
    ${props =>
        props.theme &&
        `border: 1px solid ${themes[props.theme].border};
    color: ${themes[props.theme].text};

    &::before {
        background: linear-gradient(
            305deg,
            ${themes[props.theme].accent} ${rem(accentSize / 2)},
            rgba(0, 0, 0, 0) ${rem(accentSize / 2)}
        );
        bottom: -8px;
        content: '';
        height: ${rem(accentSize * 1.75)};
        right: -8px;
        position: absolute;
        width: ${rem(accentSize)};
        z-index: 0;
    }`}
`;

export const Wrapper = styled.div`
    ${props =>
        props.theme &&
        `background-color: ${themes[props.theme].background};
    padding: ${rem(spacing.m)};
    position: relative;`}
`;
