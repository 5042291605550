/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import CTAButton from '../CTAButton';
import * as UI from '../UI/styles';
import * as S from './styles';

type Props = {
    cta?: {
        to: string,
        label: string
    },
    theme?: string,
    title?: string,
    content?: string
};

const AsideTeaser = ({cta, content, theme = 'asidePrimary', title}: Props) => (
    <S.Container theme={theme}>
        <S.Wrapper theme={theme}>
            <UI.Heading4>{title}</UI.Heading4>

            {content && <ReactMarkdown source={content} />}

            {cta && (
                <CTAButton to={cta.to} block>
                    {cta.label}
                </CTAButton>
            )}
        </S.Wrapper>
    </S.Container>
);

export default AsideTeaser;
