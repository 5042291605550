/**
 * @prettier
 */
import React from 'react';
import {graphql, Link} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import get from 'lodash/get';
import AboutTeaser from '../components/AboutTeaser';
import Container from '../components/Container';
import HeroBanner from '../components/HeroBanner';
import Page from '../components/Page';
import ResourcesHero from '../components/ResourcesHero';
import Theme from '../components/Theme';
import * as UI from '../components/UI/styles';

const ResourceDetailTemplate = ({data}) => {
    const resource = get(data, 'resource');
    const latestResources = get(data, 'latestResources');

    return (
        <Page>
            <HeroBanner title={get(resource, 'title')} />

            <UI.ResponsiveSpacer size="s" sizeAtMobile="l" />

            <Container>
                <UI.LayoutContainer css={UI.flexAtMobile}>
                    <UI.LayoutItem sizeAtMobile={6 / 12} sizeAtTablet={7 / 12}>
                        <ReactMarkdown source={get(resource, 'content.content')} />
                    </UI.LayoutItem>
                    <UI.LayoutItem sizeAtMobile={1 / 12} />
                    <UI.LayoutItem
                        sizeAtMobile={5 / 12}
                        sizeAtTablet={4 / 12}
                        style={{position: 'relative'}}
                    >
                        <UI.StickyAtMobile>
                            <AboutTeaser />
                        </UI.StickyAtMobile>
                    </UI.LayoutItem>
                </UI.LayoutContainer>
            </Container>

            <UI.ResponsiveSpacer size="l" />

            <Theme theme="primary">
                <UI.ResponsiveSpacer size="l" />
                <Container verticalPadding={false}>
                    <ResourcesHero title="Latest resources" text="" resources={latestResources} />
                </Container>
                <UI.ResponsiveSpacer size="l" />
            </Theme>
        </Page>
    );
};

export const query = graphql`
    query($slug: String!) {
        resource: contentfulPage(slug: {eq: $slug}, pageType: {eq: "Resource"}) {
            title
            slug
            tags
            content {
                content
            }
            featuredPhoto {
                large: fixed(width: 1100) {
                    width
                    height
                    src
                }
                small: fixed(width: 600) {
                    width
                    height
                    src
                }
            }
            publishDate(formatString: "MMMM Do, YYYY")
        }
        latestResources: allContentfulPage(
            filter: {pageType: {eq: "Resource"}}
            sort: {fields: [publishDate], order: DESC}
            limit: 2
        ) {
            edges {
                node {
                    title
                    slug
                }
            }
        }
    }
`;

export default ResourceDetailTemplate;
